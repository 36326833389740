@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.project_font {
    /*font-family: 'Press Start 2P', cursive;*/
    font-size: 1em;
    color:  white !important;
    padding-right: 25px !important;
}

.project_color {
    background-color: #808080;
}

@media (min-width: 900px) {
    .project_card {
        margin-bottom: 30px;
        border-radius: 20px !important;
        width: 25rem;
    }
}

@media (max-width: 900px) {
    .project_card {
        margin-bottom: 30px;
        border-radius: 20px !important;
        width: 20rem;
    }
}

.card-title {
    text-align: center;
    font-size: xx-large;
}

.button-space {
    margin-bottom: 20px;
    text-align: center;
}

.row {
    padding-left:10px;
    padding-top:20px;
}

.project_img {
    border-radius: 25px !important;
  }
  

