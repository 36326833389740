/******* Info **********/
.infoCard {
    /*  width: 98%;*/
      margin-left: 3px;
      margin-bottom: 20px;
  }
  
  .removePadding {
      padding: 0px !Important;
      margin: 0px !Important;
  }
  
  .infoCardContent {
      padding-left: 1.25rem;
      padding-bottom: 3px;
      padding-top: 2px;
  }
  
  .InfoCardContent_main {
      margin-bottom: 15px;
      min-width: 300px;
      max-width: 500px;
  }
  
  @media (min-width: 900px) {
      .InfoCardContent_main {
          margin-left:15px !Important;
          margin-right:15px !Important;
      }
  }
  
  
  .infoMain {
      border-bottom: 0px !Important;
  }
  
  @media (min-width: 900px) {
      .info_card {
          margin-bottom: 40px !Important;
      }
  }
  
  @media (min-width: 900px) {
  .info {
      width: 1000px !Important;
      }
  }
  
  @media (max-width: 900px) {
      .info {
          width: 100vw !Important;
          }
      }
  
  
  @media (min-width: 900px) {
      .container_info {
          margin-top: 50px;
          max-width: 1000px !Important;
      }
  }

  .list-group-item {
    background-color: #3a2c94 !important;
  }