.about {
    padding-left: 1.25rem;
    padding-bottom: 3px;
    padding-top: 2px;
    margin-left:20px !important;
    margin-right:20px !important;
    margin-top:20px !important;
}

.aboutSkillsCard {
    padding-top: 10px !important;
}

.aboutDetails {
    margin-bottom: 15px;
}

.border_dark {
    border-color: #231275 !important;
    border-width: 5px imp !important;
}

.border_none {
    border-width: 0px !important;
}

@media (min-width: 900px) {
    .aboutDetails {
        margin-left:20px !Important;
        margin-right:20px !Important;
    }
}

.progressbar {
    margin-right:20px !important;
    margin-top:20px !important;
}

.download {
    width: 200px;
}

@media (max-width: 900px) { 
        .download {
        margin-right: 20px
    }
}