@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
    background-image: linear-gradient(to top right, #8bffff, #8f71ff);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    overflow-x: hidden;
}

.fullscreen {
    height: 100%;
    width: 100%;
}

@media (max-width: 900px) {
    .mainHeader {
        font-size: 30px;
        padding: 10px;
    }
}

@media (min-width: 900px) {
    .mainHeader {
        font-size: 50px;
        padding: 10px;
    }
}


@media (min-width: 900px) {
    .container {
        margin-top: 50px;
        max-width: 800px !Important;
    }
}

.mainlink {
    height: 150px;
    width: 250px;
    margin: 20px;
    background-color: #231275 !important;
    color: white;
}

.mainlink_font {
    font-size: 1.5em;
    display:inline-block;
    font-family: 'Bungee', cursive;
    padding-left: 5px;
    padding-right: 5px;
}

.mainlink_icon {
    padding-top: 10px;
}

.mainlink_hover :hover{
    background-color:lightskyblue !important;
}

.navbar_font {
    font-size: 1em;
    color: white !important;
    padding-right: 25px !important;
}

.navbar_color {
    background-color: #3a2c94;
}

.text_center {
    text-align: center;
}

.container_bg {
    background-color: #3a2c94 !important;
}

.container_bg_dark {
    background-color: #231275 !important;
}

/******* color **********/
.color_red {
    color: rgb(255, 96, 96);
}

.color_yellow {
    color: yellow;
}

.color_green {
    color: rgb(14, 165, 14);
}

.color_blue {
    color: rgb(89, 89, 223);
}


.color_white {
    color: white;
}


/******* fonts **********/

.font_large {
    font-size: xx-large;
}

.font_big {
    font-size: x-large;
}

.font_normal {
    font-size: larger;
}

.font_small {
    font-size: large;
}

.font_bold {
    font-weight: bold !important;
}

.font_bolder {
    font-weight: bolder !important;
}

.font_color_grey {
    color: #808080;
}

.font_color_aqua {
    color: #00FFFF;
}

.font_nunito {
    font-family: 'Nunito', sans-serif;
}

.font_bungee {
    font-family: 'Bungee', cursive;
}
/******************* Contact **************************/
.img_round {
    border-radius: 50% !important;
    width: 200px !important;
  }

.contact_header {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
}

.contact_header_padding {
    padding: 0px !Important;
    border: 0px !Important;
    line-height: 25px !Important;
}

.no_break {
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
}
  



.project_font {
    /*font-family: 'Press Start 2P', cursive;*/
    font-size: 1em;
    color:  white !important;
    padding-right: 25px !important;
}

.project_color {
    background-color: #808080;
}

@media (min-width: 900px) {
    .project_card {
        margin-bottom: 30px;
        border-radius: 20px !important;
        width: 25rem;
    }
}

@media (max-width: 900px) {
    .project_card {
        margin-bottom: 30px;
        border-radius: 20px !important;
        width: 20rem;
    }
}

.card-title {
    text-align: center;
    font-size: xx-large;
}

.button-space {
    margin-bottom: 20px;
    text-align: center;
}

.row {
    padding-left:10px;
    padding-top:20px;
}

.project_img {
    border-radius: 25px !important;
  }
  


/******* Info **********/
.infoCard {
    /*  width: 98%;*/
      margin-left: 3px;
      margin-bottom: 20px;
  }
  
  .removePadding {
      padding: 0px !Important;
      margin: 0px !Important;
  }
  
  .infoCardContent {
      padding-left: 1.25rem;
      padding-bottom: 3px;
      padding-top: 2px;
  }
  
  .InfoCardContent_main {
      margin-bottom: 15px;
      min-width: 300px;
      max-width: 500px;
  }
  
  @media (min-width: 900px) {
      .InfoCardContent_main {
          margin-left:15px !Important;
          margin-right:15px !Important;
      }
  }
  
  
  .infoMain {
      border-bottom: 0px !Important;
  }
  
  @media (min-width: 900px) {
      .info_card {
          margin-bottom: 40px !Important;
      }
  }
  
  @media (min-width: 900px) {
  .info {
      width: 1000px !Important;
      }
  }
  
  @media (max-width: 900px) {
      .info {
          width: 100vw !Important;
          }
      }
  
  
  @media (min-width: 900px) {
      .container_info {
          margin-top: 50px;
          max-width: 1000px !Important;
      }
  }

  .list-group-item {
    background-color: #3a2c94 !important;
  }
.about {
    padding-left: 1.25rem;
    padding-bottom: 3px;
    padding-top: 2px;
    margin-left:20px !important;
    margin-right:20px !important;
    margin-top:20px !important;
}

.aboutSkillsCard {
    padding-top: 10px !important;
}

.aboutDetails {
    margin-bottom: 15px;
}

.border_dark {
    border-color: #231275 !important;
    border-width: 5px imp !important;
}

.border_none {
    border-width: 0px !important;
}

@media (min-width: 900px) {
    .aboutDetails {
        margin-left:20px !Important;
        margin-right:20px !Important;
    }
}

.progressbar {
    margin-right:20px !important;
    margin-top:20px !important;
}

.download {
    width: 200px;
}

@media (max-width: 900px) { 
        .download {
        margin-right: 20px
    }
}
